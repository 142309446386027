import React from 'react';
import Layout from "../components/layout";

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <div className="container">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
